import React, {createContext, Component} from 'react';
import axios from 'axios';

const ApiContext = createContext();
const ApiConsumer = ApiContext.Consumer;

class ApiProvider extends Component {
    constructor({initialState}) {
        super();
        this.state = {...initialState};
    }

    render() {
        const {httpConfig, actions, children} = this.props;
        const wrappedActions = {};

        const setState = (nextState) => {
            return new Promise((resolve) => {
                this.setState({...nextState}, () => resolve(this.state));
            });
        };

        Object.keys(actions).forEach((actionKey) => {
            if (actionKey === 'state') {
                console.warn(
                    'state is a reserved key, and cannot be used as an action name.',
                );
            }
            const action = actions[actionKey];
            wrappedActions[actionKey] = async (payload) => {
                const send = async (actionPayload) => {
                    const {data} = await axios({
                        ...(typeof httpConfig === 'function'
                            ? httpConfig(this.state)
                            : httpConfig),
                        ...actionPayload,
                    });
                    return data;
                };
                return action({send, payload, state: this.state, setState});
            };
        });

        return (
            <ApiContext.Provider value={{...wrappedActions, state: this.state}}>
                {children}
            </ApiContext.Provider>
        );
    }
}

ApiProvider.defaultProps = {
    httpConfig: {},
    actions: {},
    initialState: {},
};

export {ApiContext, ApiProvider, ApiConsumer};
