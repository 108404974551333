import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {ApiProvider} from './ApiContext';
import {httpConfig, actions} from './constants';

import '@shopify/polaris/styles.css';

// import { AppProvider } from '@shopify/polaris';
// import RuleForm from './components/RuleForm';
// import RuleFormLoading from './components/RuleFormLoading';

ReactDOM.render(
    <BrowserRouter>
        <ApiProvider
            httpConfig={httpConfig}
            actions={actions}
            //initialState={testInitialState}
        >
            {/* <AppProvider>
                <RuleFormLoading/>
            </AppProvider> */}
            {/* <AppProvider>
                <RuleForm/>
            </AppProvider> */}
            <App />
        </ApiProvider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
