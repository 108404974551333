import React, {memo} from 'react';
import {Card, Heading, TextStyle, TextField} from '@shopify/polaris';
import Switch from './Switch';
// import scheduledCleanIcon from '../SVG/PeriodicallyRunClean.svg';
import scheduledCleanIcon from '../SVG/PeriodicallyUnpublishProduct.svg';

const UnpublishOnTagProduct = ({rule, onChange}) => {
    const {futureUnpublish = {}} = rule;

    return (
        <Card>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 20px 0px 20px',
                }}
            >
                <div style={{flex: 1}}>
                    <Heading>Future Unpublish Product Tagging</Heading>
                </div>
                <Switch
                    checked={futureUnpublish.enabled}
                    onChange={() =>
                        onChange({
                            ...rule,
                            futureUnpublish: {
                                ...futureUnpublish,
                                enabled: !futureUnpublish.enabled,
                            },
                        })
                    }
                />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img
                    style={{width: '100px', height: '100px'}}
                    src={scheduledCleanIcon}
                    alt="Future Unpublish Product"
                />
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px 12px 12px 0px',
                    }}
                >
                    <TextStyle variation="subdued">
                        If enabled, Chameleon will ignore products tagged with a
                        date until the date and time are reached.
                        <br />
                        <div style={{height: '4px'}} />
                        <TextStyle variation="strong">
                            Tag format:
                            <br /> chameleonUnpublish=YYYY-MM-DD
                        </TextStyle>
                    </TextStyle>
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 12px 18px 100px',
                }}
            >
                <TextField
                    label={
                        <TextStyle
                            variation={
                                futureUnpublish.enabled ? null : 'subdued'
                            }
                        >
                            Run At
                        </TextStyle>
                    }
                    type="time"
                    step={60}
                    value={futureUnpublish.enabled ? futureUnpublish.runAt : ''}
                    disabled={!futureUnpublish.enabled}
                    onChange={(runAt) =>
                        onChange({
                            ...rule,
                            futureUnpublish: {
                                ...futureUnpublish,
                                runAt: runAt,
                            },
                        })
                    }
                />
            </div>
        </Card>
    );
};
export default memo(UnpublishOnTagProduct);
