import React from 'react';
import {
    Layout,
    Card,
    Page,
    SkeletonDisplayText,
    SkeletonBodyText,
    FooterHelp,
    Link,
} from '@shopify/polaris';

const RuleFormLoading = () => {
    return (
        <Page>
            <Layout>
                <Layout.Section oneThird>
                    <Card sectioned>
                        <SkeletonBodyText lines={3} />
                    </Card>
                </Layout.Section>
                <Layout.Section oneThird>
                    <Card sectioned>
                        <SkeletonBodyText lines={3} />
                    </Card>
                </Layout.Section>
                <Layout.Section oneThird>
                    <Card sectioned>
                        <SkeletonBodyText lines={3} />
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card title={<SkeletonDisplayText size="small" />}>
                        <Card.Section>
                            <SkeletonBodyText lines={2} />
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={6} />
                            <div style={{height: '12px'}} />
                            <SkeletonDisplayText size="small" />
                        </Card.Section>
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                </Layout.Section>
                <Layout.Section secondary>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText />
                    </Card>
                    <Card title={<SkeletonDisplayText size="small" />}>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                        <Card.Section>
                            <SkeletonBodyText lines={1} />
                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <FooterHelp>
                        {'Learn more '}
                        <Link url="https://www.zoocommerce.co/faq" external>
                            FAQ
                        </Link>
                    </FooterHelp>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default RuleFormLoading;
