export const isEquivalent = (a, b) => {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
        return false;
    }

    // eslint-disable-next-line
    for (const key of aKeys) {
        if (typeof a[key] === typeof b[key]) {
            if (
                typeof a[key] === 'object' &&
                a[key] != null &&
                b[key] != null
            ) {
                if (!isEquivalent(a[key], b[key])) {
                    return false;
                }
            } else if (a[key] !== b[key]) {
                return false;
            }
        } else {
            return false;
        }
    }

    return true;
};
