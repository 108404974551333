import React, {memo, useContext, useMemo} from 'react';
import {TitleBar} from '@shopify/app-bridge-react';
import {
    Page,
    PageActions,
    Layout,
    Card,
    TextStyle,
    FooterHelp,
    Link,
    // Icon,
} from '@shopify/polaris';
import {Formik} from 'formik';
import {ApiContext} from '../ApiContext';
import {isEquivalent} from '../utils';
import hideIcon from '../SVG/ProductHideBlue.svg';
import unhideIcon from '../SVG/ProductUnhideBlue.svg';
import scheduledIcon from '../SVG/PeriodicallyRunCleanBlue.svg';
import abbreviate from 'number-abbreviate';

// components
import StockLevels from './StockLevels';
import ExcludedTags from './ExcludedTags';
import FutureProduct from './FutureProduct';
import PublicationList from './PublicationList';
import UnpublishOnTagProduct from './UnpublishOnTagProduct';

// Care Icon
// import {ReactComponent as careIcon} from '../SVG/CareIcon.svg';
// import {HeartMajorMonotone} from '@shopify/polaris-icons';
// import {purple} from '@material-ui/core/colors';

const defaultRule = {
    hideLevel: 0,
    unhideLevel: 1,
    hideEnabled: true,
    unhideEnabled: false,
    tags: [],
};

const handleValidate = ({
    hideEnabled,
    unhideEnabled,
    hideLevel,
    unhideLevel,
    scheduled = {},
}) => {
    let errors = {};
    if (hideEnabled && (+hideLevel < 0 || +hideLevel % 1 !== 0)) {
        errors.hideLevel = 'Inventory level must be a positive whole number';
    }
    if (
        scheduled.hideEnabled &&
        (+scheduled.hideLevel < 0 || +scheduled.hideLevel % 1 !== 0)
    ) {
        errors.scheduledHideLevel =
            'Inventory level must be a positive whole number';
    }
    if (
        scheduled.hideEnabled &&
        (+scheduled.period < 0 || +scheduled.period % 1 !== 0)
    ) {
        errors.scheduledPeriod = 'Period days must be a positive whole number';
    }
    if (hideEnabled && unhideEnabled) {
        if (+unhideLevel <= +hideLevel) {
            errors.unhideLevel =
                'Inventory level of unhide must be greater than hide';
        }
    }
    if (scheduled.hideEnabled && unhideEnabled) {
        if (+unhideLevel <= +scheduled.hideLevel) {
            errors.unhideLevel =
                'Inventory level of unhide must be greater than hide';
        }
    }
    if (unhideEnabled && (+unhideLevel < 0 || +unhideLevel % 1 !== 0)) {
        errors.unhideLevel = 'Inventory level must be a positive whole number';
    }
    return errors;
};

const RuleForm = () => {
    const {state, fetchRule, updateRule} = useContext(ApiContext);
    // const {rule, shop, hope} = state;
    const {rule, shop} = state;
    const {hide = {}, unhide = {}, ...rest} = rule;

    const initialValues = useMemo(() => {
        return {
            ...defaultRule,
            ...rest,
            hideEnabled: hide.enabled,
            tracksInventory: hide.tracksInventory,
            unhideEnabled: unhide.enabled,
            hideLevel: hide.level,
            unhideLevel: unhide.level,
            hideCount: hide.count,
            unhideCount: unhide.count,
        };
        // eslint-disable-next-line
    }, [rule]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={async (
                {
                    hideLevel,
                    unhideLevel,
                    hideCount,
                    unhideCount,
                    hideEnabled,
                    tracksInventory,
                    unhideEnabled,
                    scheduled,
                    timezone,
                    ...values
                },
                {setSubmitting},
            ) => {
                const fmtValues = {
                    hide: {
                        enabled: hideEnabled,
                        tracksInventory: tracksInventory,
                        level: +hideLevel,
                        count: +hideCount,
                    },
                    unhide: {
                        enabled: unhideEnabled,
                        level: +unhideLevel,
                        count: +unhideCount,
                    },
                    scheduled: {
                        ...scheduled,
                        period: +scheduled.period,
                        hideLevel: +scheduled.hideLevel,
                        hideCount: +scheduled.hideCount,
                    },
                    timezone: shop.ianaTimezone,
                    ...values,
                };
                // console.log('Values at formik: ', fmtValues);
                await updateRule(fmtValues);
                await fetchRule();
                setSubmitting(false);
            }}
        >
            {(formik) => {
                const {
                    hideCount = 0,
                    unhideCount = 0,
                    hideLevel,
                    unhideLevel,
                    tags,
                    scheduled = {},
                } = formik.values;

                const noChange = isEquivalent(initialValues, {
                    ...formik.values,
                    hideLevel: +hideLevel,
                    unhideLevel: +unhideLevel,
                    tags: tags ? (tags.length > 0 ? tags : null) : null,
                });

                return (
                    <div>
                        {/* <TitleBar
                            title="Product visibility rule"
                            primaryAction={{
                                content: formik.isSubmitting
                                    ? 'Saving...'
                                    : 'Save',
                                disabled:
                                    noChange ||
                                    !formik.isValid ||
                                    formik.isSubmitting,
                                onAction: formik.handleSubmit,
                            }}
                            secondaryActions={[
                                {
                                    content: 'Discard',
                                    disabled: noChange || formik.isSubmitting,
                                    onAction: formik.handleReset,
                                },
                                {
                                    content:
                                        'Donated by Chameleon: $' +
                                        hope.chameleon,
                                    content: (
                                        <div>
                                            Donated by Chameleon:{' '}
                                            <TextStyle variation="strong">
                                                ${hope.chameleon}
                                            </TextStyle>
                                        </div>
                                    ),
                                    icon: (
                                        <Icon
                                            source={HeartMajorMonotone}
                                            color={purple}
                                        />
                                    ),
                                    url: 'https://www.zoocommerce.co/hope',
                                    external: true,
                                },
                            ]}
                        /> */}
                        <TitleBar
                            title="Product visibility rule"
                            primaryAction={{
                                content: formik.isSubmitting
                                    ? 'Saving...'
                                    : 'Save',
                                disabled:
                                    noChange ||
                                    !formik.isValid ||
                                    formik.isSubmitting,
                                onAction: formik.handleSubmit,
                            }}
                            secondaryActions={[
                                {
                                    content: 'Feature Request',
                                    url:
                                        'https://roadmap.zoocommerce.tech/b/chameleon/',
                                    external: true,
                                },
                                {
                                    content: 'Discard',
                                    disabled: noChange || formik.isSubmitting,
                                    onAction: formik.handleReset,
                                },
                            ]}
                        />
                        <Page separator={false}>
                            <Layout>
                                <Layout.Section oneThird>
                                    <Card>
                                        <div style={{display: 'flex'}}>
                                            <img
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    marginBottom: '-5px',
                                                }}
                                                src={hideIcon}
                                                alt="Hide Product"
                                            />
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '6px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Product hide has run
                                                <div>
                                                    <TextStyle variation="strong">
                                                        {abbreviate(
                                                            hideCount,
                                                            1,
                                                        )}
                                                    </TextStyle>{' '}
                                                    times.
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                    <Card>
                                        <div style={{display: 'flex'}}>
                                            <img
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    marginBottom: '-5px',
                                                }}
                                                src={unhideIcon}
                                                alt="Unhide Product"
                                            />
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '6px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Product unhide has run
                                                <div>
                                                    <TextStyle variation="strong">
                                                        {abbreviate(
                                                            unhideCount,
                                                            1,
                                                        )}
                                                    </TextStyle>{' '}
                                                    times.
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Layout.Section>
                                <Layout.Section oneThird>
                                    <Card>
                                        <div style={{display: 'flex'}}>
                                            <img
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    marginBottom: '-5px',
                                                }}
                                                src={scheduledIcon}
                                                alt="Unhide Product"
                                            />
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '6px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Product hide has run
                                                <div>
                                                    <TextStyle variation="strong">
                                                        {abbreviate(
                                                            scheduled.hideCount,
                                                            1,
                                                        )}
                                                    </TextStyle>{' '}
                                                    times.
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Layout.Section>
                                <Layout.Section>
                                    <StockLevels
                                        errors={formik.errors}
                                        rule={formik.values}
                                        onChange={formik.setValues}
                                    />
                                    <ExcludedTags
                                        rule={formik.values}
                                        onChange={formik.setValues}
                                    />
                                </Layout.Section>
                                <Layout.Section secondary>
                                    <UnpublishOnTagProduct
                                        rule={formik.values}
                                        onChange={formik.setValues}
                                    />
                                    <FutureProduct
                                        rule={formik.values}
                                        onChange={formik.setValues}
                                    />
                                    <PublicationList
                                        rule={formik.values}
                                        onChange={formik.setValues}
                                    />
                                </Layout.Section>
                                <Layout.Section>
                                    <PageActions
                                        primaryAction={{
                                            content: formik.isSubmitting
                                                ? 'Saving...'
                                                : 'Save',
                                            disabled:
                                                noChange ||
                                                !formik.isValid ||
                                                formik.isSubmitting,
                                            onAction: formik.handleSubmit,
                                        }}
                                        secondaryActions={[
                                            {
                                                content: 'Discard',
                                                disabled:
                                                    noChange ||
                                                    formik.isSubmitting,
                                                onAction: formik.handleReset,
                                            },
                                        ]}
                                    />
                                </Layout.Section>
                                <Layout.Section>
                                    <FooterHelp>
                                        Learn more about{' '}
                                        <Link
                                            url="https://www.zoocommerce.co/privacy"
                                            external={true}
                                        >
                                            Privacy Policy
                                        </Link>
                                    </FooterHelp>
                                </Layout.Section>
                            </Layout>
                        </Page>
                    </div>
                );
            }}
        </Formik>
    );
};

export default memo(RuleForm);
