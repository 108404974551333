import React, {memo, useContext} from 'react';
import {Card, Heading, TextStyle} from '@shopify/polaris';
import Switch from './Switch';
import {ApiContext} from '../ApiContext';

// const publicationList = [
//     {
//         "name": "Online Store",
//         "id": "gid://shopify/Publication/25303449646"
//     },
//     {
//         "name": "Facebook",
//         "id": "gid://shopify/Publication/26537623598"
//     },
//     {
//         "name": "Instagram",
//         "id": "gid://shopify/Publication/26837516334"
//     },
//     {
//         "name": "Amazon",
//         "id": "gid://shopify/Publication/26837549102"
//     },
//     {
//         "name": "eBay",
//         "id": "gid://shopify/Publication/26837581870"
//     },
//     {
//         "name": "Houzz",
//         "id": "gid://shopify/Publication/26837614638"
//     },
//     {
//         "name": "Messenger",
//         "id": "gid://shopify/Publication/26837680174"
//     },
//     {
//         "name": "BuzzFeed",
//         "id": "gid://shopify/Publication/26837712942"
//     },
//     {
//         "name": "Buy Button",
//         "id": "gid://shopify/Publication/26837778478"
//     },
//     {
//         "name": "Point of Sale",
//         "id": "gid://shopify/Publication/26837811246"
//     }
// ]

// const publications = [
//     {
//         "name": "Online Store",
//         "id": "gid://shopify/Publication/25303449646"
//     },
//     {
//         "name": "Facebook",
//         "id": "gid://shopify/Publication/26537623598"
//     }
// ]

const PublicationList = ({rule, onChange}) => {
    const {state} = useContext(ApiContext);
    const {publicationList = []} = state;
    const {publications = []} = rule.publications ? rule : [];

    const publicationListUI = publicationList.map(({name, id}) => {
        const checked = publications.some(
            (publication) => publication.id === id,
        );
        return (
            <Card.Section key={id} subdued={!checked}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '-8px 0px',
                    }}
                >
                    <div style={{flex: 1}}>
                        <TextStyle variation="subdued">{name}</TextStyle>
                    </div>
                    <Switch
                        checked={checked}
                        onChange={() => {
                            let publicationsUpdate;
                            if (checked) {
                                publicationsUpdate = publications.filter(
                                    (publication) => publication.id !== id,
                                );
                            } else {
                                publicationsUpdate = [
                                    ...publications,
                                    {name, id},
                                ];
                            }
                            onChange({
                                ...rule,
                                publications: publicationsUpdate,
                            });
                        }}
                    />
                </div>
            </Card.Section>
        );
    });

    if (publicationListUI.length === 0) {
        publicationListUI.push(
            <Card.Section>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <TextStyle variation="subdued">
                        No channels found...
                    </TextStyle>
                </div>
            </Card.Section>,
        );
    }

    return (
        <Card>
            <Card.Section>
                <div style={{marginBottom: '8px'}}>
                    <Heading>Publication Channels</Heading>
                </div>
                <TextStyle variation="subdued">
                    Choose which sales channels get managed by Chameleon.
                </TextStyle>
            </Card.Section>
            {publicationListUI}
        </Card>
    );
};

PublicationList.defaultProps = {
    rule: {},
    onChange: console.log,
};

export default memo(PublicationList);
