const apiUrl = process.env.REACT_APP_API_URL;

export const httpConfig = ({token}) => ({
    baseURL: `https://${apiUrl}/v1`,
    headers: token ? {Authorization: token} : undefined,
    timeout: 5000,
});

export const testInitialState = {
    token: 'test',
    shop: {
        shopOrigin: 'kidsshoecatcher.myshopify.com',
        currency: 'USD',
        timezone: '(GMT-05:00) America/New_York',
        ianaTimezone: 'America/New_York',
        planName: 'affiliate',
        chameleon: true,
    },
    hope: {
        chameleon: 0,
        turtle: 0,
        camel: 0,
    },
    publicationList: [
        {
            name: 'Online Store',
            id: 'gid://shopify/Publication/25303449646',
        },
        {
            name: 'Facebook',
            id: 'gid://shopify/Publication/26537623598',
        },
        {
            name: 'Instagram',
            id: 'gid://shopify/Publication/26837516334',
        },
        {
            name: 'Amazon',
            id: 'gid://shopify/Publication/26837549102',
        },
        {
            name: 'eBay',
            id: 'gid://shopify/Publication/26837581870',
        },
        {
            name: 'Houzz',
            id: 'gid://shopify/Publication/26837614638',
        },
        {
            name: 'Messenger',
            id: 'gid://shopify/Publication/26837680174',
        },
        {
            name: 'BuzzFeed',
            id: 'gid://shopify/Publication/26837712942',
        },
        {
            name: 'Buy Button',
            id: 'gid://shopify/Publication/26837778478',
        },
        {
            name: 'Point of Sale',
            id: 'gid://shopify/Publication/26837811246',
        },
    ],
    rule: {
        hide: {
            enabled: true,
            count: 6,
            level: 1,
            tracksInventory: true,
        },
        unhide: {
            enabled: true,
            count: 7,
            level: 4,
        },
        scheduled: {
            hideEnabled: false,
            hideCount: 2,
            hideLevel: 0,
            period: 7,
            periodCount: 3,
            runStatus: false,
            timezone: 'America/New_York',
            runAt: '01:00',
        },
        futureEnabled: true,
        tags: null,
        publications: [
            {
                name: 'Online Store',
                id: 'gid://shopify/Publication/25303449646',
            },
            {
                name: 'Amazon',
                id: 'gid://shopify/Publication/26837549102',
            },
            {
                name: 'Buy Button',
                id: 'gid://shopify/Publication/26837778478',
            },
            {
                name: 'BuzzFeed',
                id: 'gid://shopify/Publication/26837712942',
            },
            {
                name: 'eBay',
                id: 'gid://shopify/Publication/26837581870',
            },
        ],
        dateModified: '2019-05-12T05:00:00.544198Z',
        dateCreated: '2019-04-29T21:20:34.838055Z',
    },
};

export const actions = {
    fetchToken: async ({send, payload, setState}) => {
        const {url, token} = await send({
            method: 'get',
            url: 'start' + payload,
        });
        if (url) {
            window.location.replace(url);
        } else {
            return setState({token: 'Bearer ' + token});
        }
    },
    fetchShop: async ({send, setState}) => {
        const shop = await send({
            method: 'get',
            url: 'shop',
        });
        return setState({shop});
    },
    fetchHope: async ({send, setState}) => {
        const hope = await send({
            method: 'get',
            url: 'hope',
        });
        return setState({hope});
    },
    fetchPublicationList: async ({send, setState}) => {
        let publicationList = await send({
            method: 'get',
            url: 'publications',
        });

        if (Array.isArray(publicationList)) {
            publicationList.sort((a, b) => {
                const aName = a.name.toLowerCase();
                const bName = b.name.toLowerCase();
                if (aName > bName) return 1;
                if (aName < bName) return -1;
                return 0;
            });
        }

        return setState({publicationList});
    },
    fetchRule: async ({send, setState}) => {
        const rule = await send({
            method: 'get',
            url: 'rule',
        });
        return setState({rule});
    },
    updateRule: async ({send, payload, state}) => {
        const {rule} = state;
        return send({
            method: 'put',
            url: 'rule',
            data: {...rule, ...payload},
        });
    },
    runJobNow: async ({send, payload, setState}) => {
        await send({
            method: 'put',
            url: 'scheduler',
            data: {...payload},
        });

        // poll every 10 seconds
        const runInterval = setInterval(async () => {
            const rule = await send({url: 'rule', method: 'get'});
            // only if runStatus is false, update  rule in store and clear interval
            if (rule && !rule.scheduled.runStatus) {
                setState({rule});
                clearInterval(runInterval);
            }
        }, 10000);
    },
};
