import React, {useContext} from 'react';
import createApp from '@shopify/app-bridge';
import {Redirect} from '@shopify/app-bridge/actions';
import {Page, Card, TextStyle, Button} from '@shopify/polaris';
import {ApiContext} from '../ApiContext';
import axios from 'axios';

import fakeStore from '../SVG/fakeStore.svg';

const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const EnableBilling = () => {
    const {state} = useContext(ApiContext);
    const {token, shop} = state;

    const app = createApp({
        apiKey: apiKey,
        shopOrigin: shop.shopOrigin,
    });

    return (
        <Page>
            <Card>
                <div style={{display: 'flex'}}>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            padding: '20px',
                            textAlign: 'left',
                        }}
                    >
                        <div style={{paddingTop: '40px'}}>
                            <TextStyle variation="strong">
                                Oops, too keep it going we need to enable
                                billing!
                            </TextStyle>
                        </div>
                        <p style={{paddingTop: '40px'}}>
                            Please press the button to proceed with
                            subscription.
                        </p>
                        <p style={{paddingTop: '20px'}}>
                            <TextStyle variation="strong">
                                We hope you find Chameleon useful!
                            </TextStyle>
                        </p>
                        <p style={{paddingTop: '40px'}}>
                            <Button
                                size="slim"
                                primary={true}
                                onClick={() => {
                                    axios({
                                        method: 'get',
                                        url: `https://${apiUrl}/v1/tier`,
                                        headers: {
                                            Authorization: token,
                                        },
                                    })
                                        .then((res) => {
                                            if (res.data.url) {
                                                // console.log(
                                                //     'Billing url: ',
                                                //     res.data.url,
                                                // );
                                                app.dispatch(
                                                    Redirect.toRemote({
                                                        url: res.data.url,
                                                    }),
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            const message = err.response
                                                ? err.response.data.error
                                                : `Failed to fetch billing URL`;
                                            console.log(message);
                                        });
                                }}
                            >
                                Enable Subscription
                            </Button>
                        </p>
                    </div>
                    <img
                        style={{
                            width: '300px',
                            height: '300px',
                            marginBottom: '-5px',
                        }}
                        src={fakeStore}
                        alt="Fake Store"
                    />
                </div>
            </Card>
        </Page>
    );
};

export default EnableBilling;
