import React, {memo, useContext, useState} from 'react';
import {ApiContext} from '../ApiContext';
import {
    Heading,
    TextField,
    Card,
    TextStyle,
    Button,
    Checkbox,
} from '@shopify/polaris';
import {Grid, Collapse} from '@material-ui/core';
import Switch from './Switch';
import {Modal} from '@shopify/app-bridge-react';

const StockLevels = ({errors, rule, onChange}) => {
    const [modalStatus, setModalStatus] = useState(false);
    const {fetchRule, updateRule, runJobNow} = useContext(ApiContext);
    const {publications = []} = rule.publications ? rule : [];

    const {
        hideEnabled,
        tracksInventory,
        unhideEnabled,
        hideLevel,
        unhideLevel,
        scheduled = {},
    } = rule;

    return (
        <div>
            <Card>
                <Modal
                    open={modalStatus}
                    onClose={() => {
                        setModalStatus(false);
                    }}
                    title="Enable Publication Channels"
                    message="At least one Publication Channels need to be enabled!"
                    primaryAction={{
                        content: 'Close',
                        onAction: () => {
                            setModalStatus(false);
                        },
                    }}
                />
                <Card.Section>
                    <div style={{marginBottom: '8px'}}>
                        <Heading>Unpublish Actions</Heading>
                    </div>
                    <TextStyle variation="subdued">
                        Tell Chameleon when it should hide your products.
                    </TextStyle>
                </Card.Section>
                <Card.Section subdued={!(hideEnabled || scheduled.hideEnabled)}>
                    <TextField
                        label={
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <TextStyle
                                    variation={
                                        hideEnabled ? 'strong' : 'subdued'
                                    }
                                >
                                    Hide products instantly
                                </TextStyle>
                                <div style={{width: '30px'}} />
                                <Switch
                                    color="default"
                                    checked={hideEnabled}
                                    onChange={() => {
                                        onChange({
                                            ...rule,
                                            hideLevel: 0,
                                            hideEnabled: !hideEnabled,
                                            tracksInventory: !hideEnabled,
                                            scheduled: {
                                                ...scheduled,
                                                hideEnabled: false,
                                                hideLevel: 0,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        }
                        helpText={
                            errors.hideLevel ? null : (
                                <Collapse in={hideEnabled}>
                                    {`when inventory level is less than or equal to ${
                                        hideLevel ? hideLevel : '0'
                                    }`}
                                </Collapse>
                            )
                        }
                        placeholder={hideEnabled ? hideLevel : 'Disabled'}
                        type="number"
                        value={hideEnabled ? hideLevel : ''}
                        error={errors.hideLevel}
                        disabled={!hideEnabled}
                        onChange={(hideLevel) =>
                            onChange({
                                ...rule,
                                hideLevel,
                                unhideLevel:
                                    +unhideLevel <= +hideLevel
                                        ? +hideLevel + 1
                                        : unhideLevel,
                            })
                        }
                    />
                    <div style={{height: '18px'}} />
                    <Checkbox
                        label="Only affect items set to have Shopify track quantity"
                        disabled={!hideEnabled}
                        checked={tracksInventory}
                        onChange={(tracksInventory) =>
                            onChange({
                                ...rule,
                                tracksInventory,
                            })
                        }
                    />
                </Card.Section>
                <Card.Section>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <TextField
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TextStyle
                                            variation={
                                                scheduled.hideEnabled
                                                    ? 'strong'
                                                    : 'subdued'
                                            }
                                        >
                                            Hide products on schedule
                                        </TextStyle>
                                        <div style={{width: '24px'}} />
                                        <Switch
                                            color="default"
                                            checked={scheduled.hideEnabled}
                                            onChange={() => {
                                                onChange({
                                                    ...rule,
                                                    hideEnabled: false,
                                                    tracksInventory: false,
                                                    scheduled: {
                                                        ...scheduled,
                                                        hideEnabled: !scheduled.hideEnabled,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                helpText={
                                    errors.scheduledHideLevel ? null : (
                                        <Collapse in={scheduled.hideEnabled}>
                                            {`when inventory level is less than or equal to ${
                                                scheduled.hideLevel
                                                    ? scheduled.hideLevel
                                                    : '0'
                                            }`}
                                        </Collapse>
                                    )
                                }
                                placeholder={
                                    scheduled.hideEnabled
                                        ? scheduled.hideLevel
                                        : 'Disabled'
                                }
                                type="number"
                                value={
                                    scheduled.hideEnabled
                                        ? scheduled.hideLevel
                                        : ''
                                }
                                error={errors.scheduledHideLevel}
                                disabled={!scheduled.hideEnabled}
                                onChange={(hideLevel) =>
                                    onChange({
                                        ...rule,
                                        scheduled: {
                                            ...scheduled,
                                            hideLevel,
                                        },
                                        unhideLevel:
                                            +unhideLevel <= +hideLevel
                                                ? +hideLevel + 1
                                                : unhideLevel,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField
                                label={
                                    <TextStyle
                                        variation={
                                            scheduled.hideEnabled
                                                ? null
                                                : 'subdued'
                                        }
                                    >
                                        Run At
                                    </TextStyle>
                                }
                                type="time"
                                step={60}
                                value={
                                    scheduled.hideEnabled ? scheduled.runAt : ''
                                }
                                disabled={!scheduled.hideEnabled}
                                onChange={(runAt) =>
                                    onChange({
                                        ...rule,
                                        scheduled: {
                                            ...scheduled,
                                            runAt,
                                        },
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div style={{marginTop: '1px'}} />
                            <TextField
                                label={
                                    <TextStyle
                                        variation={
                                            scheduled.hideEnabled
                                                ? null
                                                : 'subdued'
                                        }
                                    >
                                        Period
                                    </TextStyle>
                                }
                                type="number"
                                min={0}
                                step={1}
                                placeholder={scheduled.hideEnabled ? '0' : null}
                                suffix="days"
                                value={
                                    scheduled.hideEnabled ? scheduled.period : 0
                                }
                                disabled={!scheduled.hideEnabled}
                                onChange={(period) =>
                                    onChange({
                                        ...rule,
                                        scheduled: {
                                            ...scheduled,
                                            period,
                                        },
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div
                                style={{marginTop: '25px', marginLeft: '20px'}}
                            >
                                <Button
                                    primary={!scheduled.runStatus}
                                    loading={scheduled.runStatus}
                                    disabled={!scheduled.hideEnabled}
                                    onClick={async () => {
                                        if (publications.length > 0) {
                                            const runNowValue = {
                                                level: +scheduled.hideLevel,
                                                publications: publications,
                                            };
                                            const disableInstantHide = {
                                                ...rule,
                                                hide: {
                                                    enabled: false,
                                                },
                                            };
                                            await updateRule(
                                                disableInstantHide,
                                            );
                                            await runJobNow(runNowValue);
                                            await fetchRule();
                                        } else {
                                            setModalStatus(true);
                                        }
                                    }}
                                >
                                    Run now
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Card.Section>
            </Card>
            <div style={{height: '24px'}} />
            <Card>
                <Card.Section>
                    <div style={{marginBottom: '8px'}}>
                        <Heading>Publish Action</Heading>
                    </div>
                    <TextStyle variation="subdued">
                        Tell Chameleon when it should unhide your products.
                    </TextStyle>
                </Card.Section>
                <Card.Section subdued={!unhideEnabled}>
                    <TextField
                        label={
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <TextStyle
                                    variation={
                                        unhideEnabled ? 'strong' : 'subdued'
                                    }
                                >
                                    Unhide products instantly
                                </TextStyle>
                                <div style={{width: '16px'}} />
                                <Switch
                                    color="default"
                                    checked={unhideEnabled}
                                    onChange={() => {
                                        onChange({
                                            ...rule,
                                            unhideEnabled: !unhideEnabled,
                                            unhideLevel:
                                                +unhideLevel <= +hideLevel
                                                    ? +hideLevel + 1
                                                    : unhideLevel,
                                        });
                                    }}
                                />
                            </div>
                        }
                        helpText={
                            errors.unhideLevel ? null : (
                                <Collapse in={unhideEnabled}>
                                    {`when inventory level is greater than or equal to ${
                                        unhideLevel ? unhideLevel : '1'
                                    }`}
                                </Collapse>
                            )
                        }
                        placeholder={unhideEnabled ? unhideLevel : 'Disabled'}
                        type="number"
                        value={unhideEnabled ? unhideLevel : ''}
                        disabled={!unhideEnabled}
                        error={errors.unhideLevel}
                        onChange={(unhideLevel) =>
                            onChange({...rule, unhideLevel})
                        }
                    />
                </Card.Section>
            </Card>
        </div>
    );
};

export default memo(StockLevels);
