import {withStyles} from '@material-ui/core/styles';
import {Switch} from '@material-ui/core';

export default withStyles({
    root: {
        width: '30px',
    },
    switchBase: {
        width: '30px',
        height: '30px',
        marginLeft: '-10px',
        '&$checked': {
            color: '#5C6AC4',
            '& + $bar': {
                backgroundColor: '#5C6AC4',
            },
        },
    },
    icon: {
        width: '16px',
        height: '16px',
    },
    bar: {
        marginLeft: '-16px',
        width: '28px',
    },
    checked: {},
})(Switch);
