import React, {memo, useState} from 'react';
import {
    Heading,
    TextField,
    Stack,
    Button,
    Card,
    TextStyle,
    Tag,
    Form,
} from '@shopify/polaris';

const ExcludedTags = ({rule, onChange}) => {
    const tags = rule.tags ? rule.tags : [];
    const [tag, setTag] = useState('');

    const tagsUI = tags.map((tag) => {
        return (
            <Tag
                key={tag}
                onRemove={() => {
                    const tagsUpdate = tags.filter((t) => t !== tag);
                    onChange({
                        ...rule,
                        tags: tagsUpdate,
                    });
                }}
            >
                {tag}
            </Tag>
        );
    });

    return (
        <div
            style={{
                padding: '24px 0px 0px 0px',
            }}
        >
            <Card sectioned>
                <div style={{marginBottom: '8px'}}>
                    <Heading>Excluded Tags</Heading>
                </div>
                <TextStyle variation="subdued">
                    Create your list of excluded product tags. Any product
                    containing an excluded tag will be unaffected by Chameleon.
                </TextStyle>
                <div style={{height: '12px'}} />
                <Form
                    onSubmit={() => {
                        if (tag !== '') {
                            if (!tags.includes(tag)) {
                                onChange({...rule, tags: [...tags, tag]});
                            }
                            setTag('');
                        }
                    }}
                >
                    <TextField
                        placeholder="Tag name"
                        name="tag"
                        autoComplete={false}
                        value={tag}
                        onChange={(tag) => setTag(tag)}
                        connectedRight={<Button submit>Add</Button>}
                    />
                </Form>
                <div style={{height: '18px'}} />
                <Stack>{tagsUI}</Stack>
            </Card>
        </div>
    );
};

export default memo(ExcludedTags);
