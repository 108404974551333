import React, {memo} from 'react';
import {Card, Heading, TextStyle, TextField} from '@shopify/polaris';
import Switch from './Switch';
import futuresIcon from '../SVG/FutureProductBlue.svg';

const FutureProduct = ({rule, onChange}) => {
    const {future = {}} = rule;

    return (
        <Card>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 20px 0px 20px',
                }}
            >
                <div style={{flex: 1}}>
                    <Heading>Future Product Tagging</Heading>
                </div>
                <Switch
                    checked={future.enabled}
                    onChange={() =>
                        onChange({
                            ...rule,
                            future: {
                                ...future,
                                enabled: !future.enabled,
                            },
                        })
                    }
                />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img
                    style={{width: '100px', height: '100px'}}
                    src={futuresIcon}
                    alt="Future Product"
                />
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px 12px 12px 0px',
                    }}
                >
                    <TextStyle variation="subdued">
                        If enabled, Chameleon will ignore products tagged with a
                        date until the date and time are reached.
                        <br />
                        <div style={{height: '4px'}} />
                        <TextStyle variation="strong">
                            Tag format:
                            <br /> chameleon=YYYY-MM-DD
                        </TextStyle>
                    </TextStyle>
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 12px 18px 100px',
                }}
            >
                <TextField
                    label={
                        <TextStyle
                            variation={future.enabled ? null : 'subdued'}
                        >
                            Run At
                        </TextStyle>
                    }
                    type="time"
                    step={60}
                    value={future.enabled ? future.runAt : ''}
                    disabled={!future.enabled}
                    onChange={(runAt) =>
                        onChange({
                            ...rule,
                            future: {
                                ...future,
                                runAt: runAt,
                            },
                        })
                    }
                />
            </div>
        </Card>
    );
};
export default memo(FutureProduct);
