import React from 'react';
import {
    Page,
    Card,
    Layout,
    TextContainer,
    Link,
    FooterHelp,
} from '@shopify/polaris';

const FAQ = () => (
    <Page separator={false}>
        <Layout>
            <Layout.Section>
                <Card title="How does Chameleon work?" sectioned>
                    <TextContainer>
                        Wherever an item is changed in Shopify (a customer
                        orders it, it is restocked, or you've updated the
                        product), Shopify notifies Chameleon of the changes.
                        Based on those events Chameleon applies the rules that
                        you set up to hide or publish the item. Hiding can be
                        set to occur immediately or even be scheduled to run at
                        a certain time and period (e.g. run a cleaning at 5 AM
                        every 7 days).
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Is there a step by step guide on how Chameleon app
                            works?"
                    sectioned
                >
                    <TextContainer>
                        Yes, you can follow this guide{' '}
                        <Link
                            url="https://www.zoocommerce.co/chameleon"
                            external
                        >
                            Chameleon
                        </Link>
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Can Chameleon replace Shopify's item scheduling
                            section?"
                    sectioned
                >
                    <TextContainer>
                        Yes, and it will even simplify publishing to all your
                        configured sales channels. Write items ahead of time and
                        simply tag them. That's it! When the date and time
                        indicated by the tag arrive, Chameleon will publish the
                        item to all the sales channels you have chosen. This is
                        much faster than using Shopify's scheduling section, and
                        as a bonus, Chameleon can even publish to social media
                        channels at the chosen time!
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card title="Can Chameleon ignore certain products?" sectioned>
                    <TextContainer>
                        Yes, you will need to apply a tag (of your choice, we
                        suggest "ignore") to products that you want to be
                        ignored by Chameleon and add that tag to Chameleon
                        settings. It will ignore any events that are happening
                        to tagged products.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Does 'Only affect items set to have Shopify track quantity' work the same way as in Shopify product page creation - 'Track quantity'?"
                    sectioned
                >
                    <TextContainer>
                        Yes, it is checking whether inventory tracking has been
                        enabled for the product.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Can Chameleon to handle products that set 'Inventory policy' to Don't track inventory?"
                    sectioned
                >
                    <TextContainer>
                        Yes, you will need to apply the same settings as when
                        you ignore certain products. Please see above!
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Can Chameleon hide individual variants (for example,
                            a shirt has large and medium sizes. If the large
                            size is out of stock, could I hide it individually)?"
                    sectioned
                >
                    <TextContainer>
                        Shopify does not currently allow hiding individual
                        variants (e.g., color, size, etc.). Shopify only allows
                        hiding a product when all variants of the product are
                        out of stock.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="Does Chameleon app work across multiple sale
                            channels: eBay, facebook, Instagram ... ?"
                    sectioned
                >
                    <TextContainer>
                        It supports all the channels that are configured under
                        your admin panel.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="At which time zone does Chameleon operate?"
                    sectioned
                >
                    <TextContainer>
                        It is based on the local time zone where your store is
                        operating.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="What happens when Chameleon is uninstalled?"
                    sectioned
                >
                    <TextContainer>
                        What happens when Chameleon is uninstalled? When the app
                        is uninstalled, products will remain in the state they
                        were in at the time. In other words, hidden items will
                        remain hidden; published items will remain published,
                        and no further actions will be made by the app. If you
                        have items scheduled to publish at a future date, you
                        will now need to use the Shopify scheduling section to
                        schedule their publish date.
                    </TextContainer>
                </Card>
            </Layout.Section>
            <Layout.Section>
                <Card
                    title="How can I contact Zoocommerce with additional
                            questions?"
                    sectioned
                >
                    <TextContainer>
                        Have other questions? Please email us at{' '}
                        <Link url="mailto:zookeeper@zoocommerce.tech" external>
                            zookeeper@zoocommerce.tech
                        </Link>
                    </TextContainer>
                </Card>
            </Layout.Section>
        </Layout>
        <FooterHelp>
            Learn more about{' '}
            <Link url="https://www.zoocommerce.co/privacy" external>
                Privacy Policy
            </Link>
        </FooterHelp>
    </Page>
);

export default FAQ;
