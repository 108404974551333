import React from 'react';
import {
    Layout,
    Card,
    Page,
    SkeletonDisplayText,
    SkeletonBodyText,
    FooterHelp,
    Link,
} from '@shopify/polaris';

const FAQLoading = () => {
    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                    <Card
                        title={<SkeletonDisplayText size="small" />}
                        sectioned
                    >
                        <SkeletonBodyText lines={4} />
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <FooterHelp>
                        Learn more about{' '}
                        <Link url="https://www.zoocommerce.co/privacy" external>
                            Privacy Policy
                        </Link>
                    </FooterHelp>
                </Layout.Section>
            </Layout>
        </Page>
    );
};

export default FAQLoading;
