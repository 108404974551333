import React, {useEffect, useContext, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Provider} from '@shopify/app-bridge-react';
import {ApiContext} from './ApiContext';
import {AppProvider} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import qs from 'qs';

// components
import RuleForm from './components/RuleForm';
import RuleFormLoading from './components/RuleFormLoading';
import FAQLoading from './components/FAQLoading';
import BlockedStore from './components/BlockedStore';
import FAQ from './components/FAQ';
import EnableBilling from './components/EnableBilling';

const apiKey = process.env.REACT_APP_API_KEY;

const centerStyle = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
};

// const App = () => {
//     return (
//         <React.Fragment>
//             <AppProvider i18n={enTranslations}>
//                 <Switch>
//                     <Route exact path="/" component={RuleForm} />
//                     <Route path="/faq" component={FAQ} />
//                 </Switch>
//             </AppProvider>
//         </React.Fragment>
//     );
// };

const App = () => {
    const {state, ...actions} = useContext(ApiContext);
    const {token, shop, hope, publicationList, rule} = state;
    const [error, setError] = useState('');
    const [routeUsed, setRouteUsed] = useState('rule');
    const [initializing, setInitializing] = useState(false);
    const [shopOrigin, setShopOrigin] = useState('');

    useEffect(() => {
        const initialize = async () => {
            try {
                setInitializing(true);
                // console.log(
                //     'shopOrigin:',
                //     qs.parse(window.location.search).shop,
                // );
                // hmac and shop url params
                setShopOrigin(qs.parse(window.location.search).shop);
                // fetching token
                await actions.fetchToken(window.location.search);
            } catch (e) {
                if (e.response) {
                    setError(
                        e.response.data
                            ? e.response.data
                            : e.response.status + ' Error',
                    );
                } else {
                    setError(e.message);
                }
                setInitializing(false);
            }
        };
        initialize();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (token) {
            const fetchAll = async () => {
                try {
                    const shopPromise = actions.fetchShop(token);
                    const hopePromise = actions.fetchHope(token);
                    const pubListPromise = actions.fetchPublicationList(token);
                    const rulePromise = actions.fetchRule(token);
                    await shopPromise;
                    await hopePromise;
                    await pubListPromise;
                    await rulePromise;
                } catch (e) {
                    if (e.response) {
                        setError(
                            e.response.data
                                ? e.response.data
                                : e.response.status + ' Error',
                        );
                    } else {
                        setError(e.message);
                    }
                } finally {
                    setInitializing(false);
                }
            };
            fetchAll();
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (shop) {
            window.FS.setUserVars({
                displayName: shopOrigin || 'localDevUser',
                shopifyPlan_str: shop.planName,
            });
        }
    }, [shop, shopOrigin]);

    const hasAllData = token && shop && hope && publicationList && rule;
    const zooConfig = {
        apiKey: apiKey,
        shopOrigin: shopOrigin,
        forceRedirect: true,
    };

    return (
        <React.Fragment>
            {initializing && (
                <AppProvider i18n={enTranslations}>
                    <Provider config={zooConfig}>
                        {routeUsed === 'rule' ? (
                            <RuleFormLoading />
                        ) : (
                            <FAQLoading />
                        )}
                    </Provider>
                </AppProvider>
            )}
            {!initializing && error && (
                <AppProvider i18n={enTranslations}>
                    <Provider config={zooConfig}>
                        <div style={centerStyle}>{error} :(</div>
                    </Provider>
                </AppProvider>
            )}
            {!initializing && !error && hasAllData && (
                <AppProvider i18n={enTranslations}>
                    <Provider config={zooConfig}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => {
                                    setRouteUsed('rule');
                                    return shop.chameleon.billingEnabled ? (
                                        shop.planName === 'trial' ||
                                        shop.planName === 'affiliate' ||
                                        shop.planName === 'dormant' ? (
                                            <BlockedStore />
                                        ) : (
                                            <RuleForm />
                                        )
                                    ) : (
                                        <EnableBilling />
                                    );
                                }}
                            />
                            <Route
                                path="/faq"
                                render={() => {
                                    setRouteUsed('faq');
                                    return <FAQ />;
                                }}
                            />
                        </Switch>
                    </Provider>
                </AppProvider>
            )}
        </React.Fragment>
    );
};

export default App;
